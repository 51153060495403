.mainwrapper{
    /* width:100vw;
    height: 100vh; */
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-color: black;
    background-size: cover;
    position: relative;
    /* overflow: hidden; */
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    gap: 20px;
    flex-direction: column;
    
  }

  .body_wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    gap: 10px;
  }

  .title{
    color:white;
    font-family:Arial;
    margin: 0;
    -webkit-text-stroke: 2px black;
  }

  .title_wrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
  }

  .text{
    color:white;
    margin: 0;
    font-family: "Average Sans";
  }

  .form_wrapper{
    width: 80%;
    background-color: white;
    border-radius: 30px;
  }

  @media screen and (max-width:500px) {

    .title_wrapper{
        gap:25px;
    }

    .title{
        font-size:44px;
    }

    .text{
        font-size: 14px;
        line-height:28px;
        text-align: center;
    }

    .form_wrapper{
        padding: 10px;
        margin: 10px;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .title{
        font-size: 56px;
    }

    .title_wrapper{
        gap:25px;
    }

    .text{
        font-size: 24px;
        line-height:40px;
        text-align: center;
    }

    .form_wrapper{
        padding: 15px;
        margin: 20px;
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .title{
        font-size: 80px;
    }

    .title_wrapper{
        gap:25px;
    }

    .text{
        font-size: 24px;
        line-height:44px;
        text-align: start;
        max-width: 50%;
    }

    .form_wrapper{
        padding: 20px;
        margin: 40px;
    }

  }

  @media screen and (min-width:1921px){

    .title{
        font-size: 100px;
    }

    .title_wrapper{
        gap:25px;
    }

    .text{
        font-size: 30px;
        line-height:58px;
        text-align: start;
        max-width: 50%;
    }

    .form_wrapper{
        padding: 20px;
        margin: 50px;
    }

  }

