.mainwrapper{
    width:100vw;
    height: 100vh;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-repeat:no-repeat;
    background-color: black;
    background-size: cover;
    position: relative;
    /* overflow: hidden; */
}
  
  .subwrapper{
    width:100%;
    height:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    position: relative;
    padding:10px;
    
  }

  .body_wrapper{
    display:flex;
    flex-direction: column;
    justify-content: center;
    box-sizing: border-box;
    gap: 10px;
  }

  .image_wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .title{
    color:white;
    font-family:Arial;
    margin: 0;
    -webkit-text-stroke: 2px black;
  }

  .title_wrapper{
    display:flex;
    flex-direction:row;
    align-items:center;
  }

  .text{
    color:white;
    margin: 0;
    font-family: "Average Sans";
  }

  .banner{
    position: absolute;
    width: 100%;
    height: 75px;
    left: 0;
    object-fit:cover;
    z-index: 1;
  }

  @media screen and (max-width:500px) {

    .mainwrapper{
        max-height: 460px;
        min-height: 500px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .body_wrapper{
        align-items: center;
        padding: 10px;
    }

    .image{
        width:260px;
        object-fit: contain;
    }

    .title_wrapper{
        gap:25px;
    }

    .title{
        font-size:44px;
    }

    .text{
        font-size: 14px;
        line-height:28px;
        text-align: center;
    }

  }

  @media screen and (min-width:501px) and (max-width:1023px) {

    .mainwrapper{
        max-height: 680px;
        min-height: 600px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: column;
    }

    .body{
        padding: 100px;
        padding-top:100px;
        gap:25px;
    }

    .body_wrapper{
        align-items: center;
        padding: 30px;
    }

    .image{
        width:360px;
        object-fit: contain;
    }

    .title{
        font-size: 56px;
    }

    .title_wrapper{
        gap:25px;
    }

    .text{
        font-size: 24px;
        line-height:40px;
        text-align: center;
    }

  }

  @media screen and (min-width:1024px) and (max-width:1920px) {

    .mainwrapper{
        max-height: 740px;
        min-height: 700px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body{
        padding: 100px;
        padding-top:150px;
        gap:25px;
    }

    .body_wrapper{
        align-items: flex-start;
        padding: 30px;
        padding-left: 100px;
    }

    .image{
        width:900px;
        opacity: 0.9;
        object-fit: contain;
    }

    .title{
        font-size: 80px;
    }

    .title_wrapper{
        gap:25px;
    }

    .text{
        font-size: 24px;
        line-height:44px;
        text-align: start;
        max-width: 50%;
    }

  }

  @media screen and (min-width:1921px){

    .mainwrapper{
        max-height: 920px;
        min-height: 900px;
    }

    .subwrapper{
        max-width: 2200px;
        flex-direction: row;
    }

    .body_wrapper{
        align-items: flex-start;
        padding: 10px;
        padding-left: 100px;
    }

    .image{
        width:1000px;
        object-fit: contain;
    }

    .title{
        font-size: 100px;
    }

    .title_wrapper{
        gap:25px;
    }

    .text{
        font-size: 30px;
        line-height:58px;
        text-align: start;
        max-width: 50%;
    }

  }

